import { useState, useEffect } from 'react';
import Router from 'next/router';
import { useCookies } from 'react-cookie';

import { timeConverter } from '../../helpers/jwt';
import { useStore as authStore } from '../../contexts/auth';
import { useStore as historyStore } from '../../contexts/history';
import useGQL from './useGQL';
import useGQLQuery from './useGQLQuery';
import useRedirect from './useRedirect';
import useApp from './useApp';
import useJWT from './useJWT';

// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

const useAuth = () => {
	const localStorageNm = 'access_token';
	const [, setCookie, removeCookie] = useCookies([localStorageNm]);

	const [isDev] = useState(process.env.NODE_ENV === 'development');

	const { isJwtTokenValidClient, parseJwt, getJWTData } = useJWT();
	const { redirect } = useRedirect();
	const { prevRoute } = historyStore();

	const [isSSR] = useState(typeof window === 'undefined');
	const { appDispatch } = useApp();
	const { data: authData, dispatch } = authStore();
	const { authenticated } = authData;

	const { userData, contactData } = authData;

	// Local storage token handle

	const saveAccessToken = (token) => {
		if (isSSR === false) {
			window.localStorage.setItem(localStorageNm, token);
			setCookie(localStorageNm, token, { path: '/' });
		}
	};

	const unsetAccessToken = () => {
		if (isSSR === false) {
			window.localStorage.removeItem(localStorageNm);
			removeCookie(localStorageNm, { path: '/' });
		}
	};

	const getAccessToken = () => {
		const token = isSSR === false ? window.localStorage.getItem(localStorageNm) : null;

		if (token) {
			if (isJwtTokenValidClient(token)) {
				return token;
			}

			// If not valid - unset!
			unsetAccessToken();
		}

		return null;
	};

	// Login or "refresh token"
	const login = (token, redirectBack = false, forceLoadProfiledata = false) => {
		const t = token || getAccessToken();

		if (t) {
			// Local storage/cookie handle
			saveAccessToken(t);

			// Global state handle
			dispatch({
				type: 'LOGIN',
				payload: {
					token: t
				}
			});

			// Load user/contact = profile data
			loadProfileData(forceLoadProfiledata, getJWTData(t));
		} else {
			dispatch({ type: 'NOT_AUTHENTICATED' });
		}

		if (redirectBack) {
			const lastRoute = prevRoute();
			Router.push((!lastRoute || (lastRoute.indexOf('forgot-password') !== -1 || lastRoute.indexOf('register') !== -1 || lastRoute.indexOf('login') !== -1)) ? '/' : lastRoute);
		}

		return false;
	};

	// Mutate, refresh token
	const { mutation: mutateRefreshToken } = useGQL('auth>refresh-access-token');

	// Login & logout
	const { mutation: mutateLogout } = useGQL('auth>logout');

	const logout = () => {
		const token = getAccessToken();

		if (token) {
			// Server logout
			mutateLogout({ access_token: token }).then((d, e) => {
				if (isDev) console.log('User logged out.');
			});

			// Local storage handle
			unsetAccessToken(token);

			// Destroy user & contact data in context
			dispatch({ type: 'SET_USER_DATA', payload: {} });
			dispatch({ type: 'SET_CONTACT_DATA', payload: {} });

			// Reset layout (on mobile), when user profile & logout button is @ overlay
			appDispatch({ type: 'SET_OVERLAY_PANEL', payload: null });
		}

		// Global state handle
		dispatch({
			type: 'LOGOUT'
		});
	};

	// User data

	const loginExpirationDtm = () => {
		const token = getAccessToken();

		if (token) {
			const d = parseJwt(token);
			return (d && d.exp) ? timeConverter(d.exp) : null;
		}

		return null;
	};

	const getUser = () => {
		if (userData) return userData;
		return {};
	};

	// Query
	// const [variables, setVariables] = useState({ user: {}, contact: {} });

	const onFetch = (name, result, error) => {
		if (result) {
			if (result) dispatch({ type: `SET_${name.toUpperCase()}_DATA`, payload: result });
			if (error) logoutIfTokenInvalid(result);

			// Reset variables
			if (name === 'user') setVarsUser({});
			else setVarsContact({});
		}
	};

	// Query, user
	const { result: profileUserData, error: profileUserErr, setVariables: setVarsUser } = useGQLQuery(null, 'user>item', {}, { enabled: false, refetch_vars_not_empty: true });
	useEffect(() => onFetch('user', profileUserData, profileUserErr), [profileUserData, profileUserErr]);
	const loadUser = id => setVarsUser({ user_id: id });

	// Query, contact
	const { result: profileContactData, error: profileContactErr, setVariables: setVarsContact } = useGQLQuery(null, 'contact>item', {}, { enabled: false, refetch_vars_not_empty: true });
	useEffect(() => onFetch('contact', profileContactData, profileContactErr), [profileContactData, profileContactErr]);
	const loadContact = id => setVarsContact({ contact_id: id });

	const logoutIfTokenInvalid = (e) => {
		// If users token is invalid after server validation - logout
		if (e.errorCode === 'not-authenticated') {
			logout();
			if (isDev) console.log('User is forced logout. Token is not valid on server.');
		}
	};

	const reloadProfiledata = (sections) => {
		const jwtData = getJWTData(authData.token);

		if (sections.indexOf('user') !== -1 && jwtData.user_id) loadUser(jwtData.user_id);
		if (sections.indexOf('contact') !== -1 && jwtData.contact_id) loadContact(jwtData.contact_id);
	};

	const loadProfileData = (force = false, aData = {}) => {
		if (force === true || (Object.keys(userData).length === 0 && userData.constructor === Object)) {
			loadUser(aData.user_id);
		}

		if (force === true || (Object.keys(contactData).length === 0 && contactData.constructor === Object)) {
			loadContact(aData.contact_id);
		}
	};

	// Quick view

	const openQuickView = (step, callback, onAuthenticated) => {
		if (!authenticated) {
			dispatch({ type: 'QUICK_VIEW_SET_STEP', payload: { step, callback } });
			return true;
		}

		if (onAuthenticated) {
			if (typeof onAuthenticated === 'function') {
				onAuthenticated();
			} else if (onAuthenticated.indexOf('redirect:') !== -1) { // ex. redirect:pricelist => /cenik
				redirect(onAuthenticated.split(':')[1] || null);
			}
		} else {
			callback();
		}

		return true;
	};

	return { authData, authenticated, userData, contactData, dispatch, login, logout, getAccessToken, reloadProfiledata, getUser, loginExpirationDtm, openQuickView, logoutIfTokenInvalid };
};

export default useAuth;
